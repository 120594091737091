.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.form-control:disabled, .form-control[readonly] {
    /*background-color: lightgoldenrodyellow*/
}

.fc-event {
    width: 40% !important;
}
.resource_1, a.resource_1, .resource_1:focus {    color: white;    background-color: #8DC34B !important}
.resource_2, a.resource_2, .resource_2:focus { color: white; background-color: #4F6272 !important}
.resource_3, a.resource_3, .resource_3:focus { color: white; background-color: #EB1F66 !important}
.resource_4, a.resource_4, .resource_4:focus { color: white; background-color: #9A29AF !important}
.resource_5, a.resource_5, .resource_5:focus { color: white; background-color: #775643 !important}
.resource_6, a.resource_6, .resource_6:focus { color: white; background-color: #CCAC00 !important}
.resource_7, a.resource_7, .resource_7:focus { color: white; background-color: #DD7596 !important}
.resource_8, a.resource_8, .resource_8:focus { color: white; background-color: #2274A5 !important}
.resource_9, a.resource_9, .resource_9:focus { color: white; background-color: #7f1f4e !important}
.resource_10, a.resource_10, .resource_10:focus { color: white; background-color: #5e2121 !important}
.resource_11, a.resource_11, .resource_11:focus { color: white; background-color: #2396F3 !important}
.resource_12, a.resource_12, .resource_12:focus { color: white; background-color: #FF9605 !important}
.resource_13, a.resource_13, .resource_13:focus { color: white; background-color: #775643 !important}
.resource_14, a.resource_14, .resource_14:focus { color: white; background-color: #4D372B !important}
.resource_15, a.resource_15, .resource_15:focus { color: white; background-color: #404E5C !important}
.resource_16, a.resource_16, .resource_16:focus { color: white; background-color: #adbd20 !important}
.resource_17, a.resource_17, .resource_17:focus { color: white; background-color: #8DC34B !important}
.resource_18, a.resource_18, .resource_18:focus { color: white; background-color: #00968B !important}
.resource_19, a.resource_19, .resource_19:focus { color: white; background-color: #6639B8 !important}
.resource_20, a.resource_20, .resource_20:focus { color: white; background-color: #9E9E9E !important}
.resource_21, a.resource_21, .resource_21:focus { color: white; background-color: #131B23 !important}
.resource_22, a.resource_22, .resource_22:focus { color: white; background-color: #0059b0 !important}
.resource_23, a.resource_23, .resource_23:focus { color: white; background-color: #771334 !important}
.resource_24, a.resource_24, .resource_24:focus { color: white; background-color: #d059fC !important}
.resource_25, a.resource_25, .resource_25:focus { color: white; background-color: #ca931D !important}
.resource_26, a.resource_26, .resource_26:focus { color: white; background-color: #71a7a2 !important}
.resource_27, a.resource_27, .resource_27:focus { color: white; background-color: #2c3d72 !important}
.resource_28, a.resource_28, .resource_28:focus { color: white; background-color: #c01a50 !important}
.resource_29, a.resource_29, .resource_29:focus { color: white; background-color: #5673e4 !important}
.resource_30, a.resource_30, .resource_30:focus { color: white; background-color: #883a59 !important}
.resource_31, a.resource_31, .resource_31:focus { color: white; background-color: #f8541C !important}
.resource_32, a.resource_32, .resource_32:focus { color: white; background-color: #29d42E !important}


/*hamburgher Menu*/
/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}


/*Custom Dialog modal size*/
.modal-90w {
    width: 90%;
    max-width: none !important;
}

/*FullCalendar minimum height*/
.fc-event {
    min-height: 15px;
}

body {
    /*background-color: #212529*/
}

.form-control-user, .form-control-user:focus {
    border: 1px solid #ced4da;
    color: #f9fafb;
    background-color: #222222
}

[data-f-id="pbf"] {
    display: none
}